<template>
   <div class="details">
       <div class="main">
           <div class="imgAndDes">
                <div class="parameter">
                    <img :src="itemObj.detailImg || itemObj.image" alt="">
                </div>
                <div class="describe" v-if="itemObj.describe">{{itemObj.describe}}</div> 
           </div>
            
            <div class="featuresapplication">
                <div class="features" v-if="itemObj.features">
                    <div class="name">特征</div>
                    <div class="item" v-for="(item,index) in itemObj.features" :key="index">{{item}}</div>
                </div>
                <div class="features" v-if="itemObj.application">
                    <div class="name">应用</div>
                    <div class="item" v-for="(item,index) in itemObj.application" :key="index">{{item}}</div>
                </div>
            </div>
            <el-table style="width: 800px;margin: 30px 0;" border v-if="itemObj.table" :data="itemObj.table"> 
                <el-table-column prop="name" label="型号"></el-table-column>
                <el-table-column prop="Spectral" label="光谱范围nm"></el-table-column>
                <el-table-column prop="slit" label="狭缝um"></el-table-column>
                <el-table-column prop="power" label="分辨率（典型值）"></el-table-column>
            </el-table>
            <el-table style="width: 800px;" border :data="getValues" :show-header="false" class="tableStyle">
                <el-table-column :width="item.width" v-for="(item, index) in getHeaders" :key="index" :prop="item"></el-table-column>
            </el-table>
       </div>
   </div>
</template>

<script>
import {productConst} from '@/data/index'
export default {
    components: {},

    data() {
        return {
            itemObj:{
                headList: [],
                tableList: [] 
            },
            
        };
    },

    computed: {
        getHeaders() {
            return this.itemObj.tableList.reduce((pre, cur, index) => pre.concat(`value${index}`), ['title'])
        },
        getValues() {
            return this.itemObj.headList.map(item => {
                return this.itemObj.tableList.reduce((pre, cur, index) => Object.assign(pre, {['value' + index]: cur[item.prop]}), {'title': item.label,});
            });
        }
    },

    mounted () {
        this.$nextTick(()=>{
            let index = this.$route.query.index
            this.itemObj = productConst[index -1]
        })
    },

    methods: {},
}
</script>

<style lang='scss' scoped>
.details{
    padding: 80px 0 50px 0;
    
    .describe{
        text-indent:2em;
        font-size: 18px;
        line-height: 30px;
        width:1000px;
        margin: 0 auto 20px;
        color:#606266;
    }
    .featuresapplication{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .features{
            color:#606266;
            .name{
                font-size: 30px;
                font-weight: 500;
                text-align: center;
                margin-bottom: 20px;
            }
            .item{
                font-size: 16px;
                line-height: 30px;
            }
        }
    }
    .tableStyle{
        margin-top:30px;
        font-size: 16px;
    }
}
.imgAndDes{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    .parameter{
        flex: 0 0 550px;
        margin-right: 20px;
        img{
            width:100%;
        }
    }
}
</style>